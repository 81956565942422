<template>
	<PopupMetier name="metier-laborantin">
		<ul slot="list__1">
			<li><router-link to="/metier-laborantin/controle-de-performance">Contôle de performances</router-link></li>
			<li><router-link to="/metier-laborantin/comptabilite-controle-de-gestion">Comptabilité - Contrôle de Gestion</router-link></li>
			<li><router-link to="/metier-laborantin/ordonnancement">Ordonnancement</router-link></li>
		</ul>
		<ul slot="list__2">
			<li><router-link to="/metier-laborantin/medecine-du-travail">Médecine du travail</router-link></li>
			<li><span class="subuniting">Métiers supports</span></li>
			<li><router-link to="/metier-laborantin/rh">Ressources Humaines</router-link></li>
			<li><router-link to="/metier-laborantin/communication">Communication</router-link></li>
			<li><router-link to="/metier-laborantin/achats">Achats</router-link></li>
			<li><router-link to="/metier-laborantin/cadre-de-vie">Cadre de Vie</router-link></li>
		</ul>
		<ul slot="list__3">
			<li><router-link to="/metier-laborantin/procedes">Procédés</router-link></li>
			<li><router-link to="/metier-laborantin/laboratoire">Laboratoire</router-link></li>
		</ul>
	</PopupMetier>
</template>

<script>
	export default {
		components: {
			PopupMetier: () => import('@/components/PopupMetier.vue'),
		},
	}
</script>
